import { Box } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from './Components/Footer'
import Navbar from './Components/Navbar'
import { Button } from './styles/Button'

const ErrorPage = () => {
  return (
    <>
    <Navbar />
    <Box style={{marginTop:80}}>
      <Box className='container' >

        
      <h1 style={{fontSize:40,marginTop:20}}>Error</h1>
      <h1 style={{fontSize:40,marginTop:10}}>404</h1>
      <h2 style={{fontSize:40,marginTop:10}}>Page Not Found</h2>
      <p style={{fontSize:18,marginTop:15}}>It appears the page you were looking for couldn’t be found.</p>
        
        <Button style={{marginTop:30}}>
          <NavLink to='/'>
            Go back to Home
          </NavLink>
        </Button>
        
    </Box>
    </Box>
    <Footer />
    </>
  )
}

export default ErrorPage
