import React from 'react'
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'

const Kufi = () => {
  return (
    <div>
        <Navbar />
        <div style={{marginTop:'130px'}}>
      <h2>Why Modestile,</h2> <br /> <br />

     <h3>Modest Kufi Hats</h3> <br />
     <p>Visit for preiemum quality men's prayer kufi hats collection. New arrival's in men's kufi hats articles. Latest trend design's inspired by all season's trend. High quality imported polyester fabric machine stitched hand made design's zero niddle stitched machine fuesed no bubble in cap guarented. Sensational finishing class of these caps which can look your wardrobe more attractive. </p> <br /> <br />

     <h3>Innovative Designs Tailored for the Western Muslim</h3> <br />
     <p>Our talented team, led by a Parsons School of Design graduate, is dedicated to creating original patterns specifically designed for the Western Muslim community. The craftsmanship of our designers and pattern cutters sets Modestile apart in delivering unique and innovative designs.</p> <br /> <br />

     <h3>Quantity of Fabric for Modest Fit</h3> <br />
     <p>Our Islamic designs require a substantial amount of fabric, as  garments are cut longer and wider for a more modest fit. This commitment to modesty often results in the use of double the fabric compared to mainstream designs, contributing to the overall excellence of our products.</p> <br /> <br />

     <h3>Quality and Variety of Premium Materials</h3> <br />
     <p>Our Islamic designs require a substantial amount of fabric, as garments are cut longer and wider for a more modest fit. This commitment to modesty often results in the use of double the fabric compared to mainstream designs, contributing to the overall excellence of our products.</p> <br /> <br />

     <h3>High-Quality Production and Lean Manufacturing</h3> <br />
     <p>Adhering to the highest industry standards, Modestile's garments undergo a strict quality control process. Our lean-manufacturing production process fosters higher employee engagement, better employment terms, superior product quality, and faster delivery times.</p> <br /> <br />


     <h3>Catering to a Niche Market with Unique Value</h3> <br />
     <p>Operating in the niche Islamic clothing market, Modestile distinguishes itself by crafting unique and meticulously designed pieces. While our prices may seem higher initially, we believe our customers find value in the quality, ethical practices, and individuality that define Modestile's clothing.</p> <br /> <br />
     </div>

     <Footer />
    </div>
  )
}

export default Kufi
