import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Navbar from './Navbar'

const Header = () => {
  return (
    <>
        <Navbar />
    </>
  )
}

const MainHeader = styled.header`
padding: 0 4.8 rem;
height: 10rem;
background-color: ${({theme}) => theme.colors.navbar};
display: flex;
justify-content: space-between;
align-items: center;
position: relative;

.logo{
    height: 5rem
}
`

export default Header
