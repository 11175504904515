import { Outlet } from "react-router-dom";

function Coupon() {
    return (
        <Outlet />
    )
};



export default Coupon;