import React from 'react'

const FullScreenLoader = () => {
  return (
    <div>
      <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'400px'}}>
          <img src="images/loading.gif" alt=""  className='img-style' width='70%'/>
    </div>
    </div>
  )
}

export default FullScreenLoader
