import React from 'react'
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'

const Thanks = () => {
    const {id} = useParams()
  return (
    <div>
        <Navbar />
        <div style={{textAlign:'center',marginTop:150}}>
   <h2>Thanks for Order</h2>
   <h3 style={{marginTop:15}}>Your Order Number is {id}</h3>
   <Container>
   <p style={{marginTop:25,fontSize:20}}>INSHA ALLAH, You will be delivered the order in 5 to 7 days. <br /> For more information please contact <a href="mailto:info@modestile.pk">info@modestile.pk</a> <br /> or <a href="tel: +92 313 1688987">+92 313 1688987</a> and share your order number.</p> 
   
   </Container>
 </div>
      <Footer />
    </div>
  )
}

export default Thanks
