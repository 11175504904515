import React from 'react'
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const PageNavigation = ({hamza}) => {
  return (
    <Wrapper>
    <NavLink to="/" style={{color:'#edc531',textDecoration:'underline'}}>Home</NavLink>/{hamza}
  </Wrapper>
  )
}

const Wrapper = styled.section`
  height: 7rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2.2rem;
  padding-left: 1.2rem;
  width:100%
  a {
    font-size: 3.2rem;
  }
  @media (max-width: 768px) {
    background-color: white;
    
  }
`;

export default PageNavigation
