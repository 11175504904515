import { Container } from '@mui/system'
import React from 'react'
import styled from 'styled-components'
import Footer from './Components/Footer'
import Navbar from './Components/Navbar'

const Shipping = () => {
    return (
        <div style={{marginTop:120}}>
            <Navbar />
            <Shippingstyle>
            <Container>
                <h2 style={{textAlign:'center',fontSize:33,marginTop:40}}>Shipping Information</h2>
                <ul>
                    <li>
                        Once you place an order you will receive a verification code to enter for order
                        confirmation if the verification code is not received, a call will be made from our end
                        for order confirmation.
                    </li>
                    <li>
                        You may track the shipping status of your order by clicking on tracking link sent to you
                        via email by our Customer Support Department.
                    </li>
                    <li>
                        Cash on delivery orders will be cancelled if they are not confirmed via verification
                        code/call within 3 days of order placement.
                    </li>
                    <li>
                        We reserve the rights to cancel the orders any time before the order is processed,
                        reasons may include: Out of stock items, pricing or technical errors.
                    </li>
                    <li>
                        For International orders, free shipping on orders above USD $200.
                    </li>
                    <h4 style={{fontSize:20,marginTop:15}}> Domestic Delivery </h4>
                    <li style={{marginTop:8}}>
                        4-6 working days
                    </li> <br />
                    <li>
                        During Sale Period Orders may be delivered in 6-8 working days.
                    </li> <br />

                        <h4 style={{fontSize:20,marginTop:15}}>  Tailor Made Delivery </h4>
                        <li style={{marginTop:8}}>
                            Tailor Made orders will be Delivered within 12-15 Working Days

                        </li> <br />
                            
                            <h4 style={{fontSize:20,marginTop:15}}> International Delivery</h4>
                             <li style={{marginTop:8}}>
                             10-15 working days
                         </li>
                         <li>
                             During Sale Period Orders may be delivered in 8-10 working days.
                         </li> <br />
                         <h4 style={{fontSize:20,marginTop:15}}>Delivery Charges</h4>
                           <li style={{marginTop:8}}> Delivery Charges
                            Charges of international orders are calculated based on the destination and the weight of
                            your shipment. Duties and taxes are most typically not included in the price of the goods
                            you purchase online, and might not be included in the overall shipping costs you pay. Also,
                            please ensure to check the Duty charges (if any) of your shipment as the duty charges will
                            be payable to the courier company at the time of the delivery. </li>

                            <li> Please note that international orders may be subject to taxes & duties and will be
                            responsibility of the customer. Any applicable duties or fees (If any) will be collected at
                            the time of order delivery. </li>

                            </ul>
            </Container>
            </Shippingstyle>
            <Footer />
        </div>
    )
}

export default Shipping

const Shippingstyle = styled.section`
li{
    font-size: 20px;
    margin-top: 20px;
    list-style: circle;
}

`
