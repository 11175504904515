import { Container } from '@mui/system'
import React from 'react'
import Footer from './Components/Footer'
import Navbar from './Components/Navbar'

const Privacy = () => {
    return (
        <div style={{marginTop:120}}>
            <Navbar  />
            <Container>
            <div>
                <h1 style={{textAlign:'center',marginTop:35}}>Privacy Policy</h1>
            </div>

            <div style={{marginTop:30}}>
                <p style={{fontSize:20}}>Whether you register with us or use the site anonymously, the information will only be used
                    to fill process and ship your orders, contacting you from customer service department,
                    improving your browsing experience of the website and informing you of our promotional
                    events/activities through newsletter, messaging etc. <br /> <br />

                    By browsing Modestile website, you agree to accept all our policies, which may be subject to
                    change any time. <br /> <br />

                    If you require any further information related to our website privacy policy you can contact
                    us by emailing us at info@modestile.pk or call us at our Tel: +92 313 1688987
                </p>
            </div>
            </Container>
            <Footer />
        </div>
    )
}

export default Privacy
