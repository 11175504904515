import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {  deleteItem, getData } from '../../firebase/firebasemethod';
import Paper from '@mui/material/Paper';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { Button, ButtonBase } from '@mui/material';


export default function Review() {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        getData('Review')
            .then((res) => {
                setData(Object.values(res))
                // console.log(res);
                setIsLoading(false)
            })
            .catch((err) => {
                alert(err);
                setIsLoading(false)
            })
    }, []);

    const Delete=(id)=>{
        deleteItem('Review', id)
     }

    
    // console.log(data)

    return (
    
        <div>
            <Box sx={{mt:1}}>
            
            <h3 className='regisdetail' style={{textAlign:'center',fontSize:25}}>All Review Details</h3>
            {
                isLoading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                        <CircularProgress />
                    </Box>
                    :

                    <Table responsive hover sx={{mr:20}} component={Paper}>
                        <thead>
                            <tr>
                                <th style={{paddingLeft:10,paddingRight:10,fontSize:15}}>#</th>
                                <th style={{paddingLeft:40,paddingRight:40,fontSize:15}}>Name</th>
                                <th style={{paddingLeft:40,paddingRight:40,fontSize:15}}>Email</th>
                                <th style={{paddingLeft:40,paddingRight:40,fontSize:15}}>Rating</th>
                                <th style={{paddingLeft:40,paddingRight:40,fontSize:15}}>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td style={{fontSize:15,textAlign:'center'}}>{i + 1}</td>
                                            <td style={{fontSize:15,textAlign:'center'}}>{e.name}</td>
                                            <td style={{fontSize:15,textAlign:'center'}}>{e.email}</td>
                                            <td style={{fontSize:15,textAlign:'center'}}>{e.star}</td>
                                            <td>
                                            <Button onClick={()=>Delete(e.id)} sx={{ color: 'black' }}>Delete</Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
            }
            </Box>
            
        </div>
    );
}