import { Outlet } from "react-router-dom";

function AddProduct() {
    return (
        <Outlet />
    )
};



export default AddProduct;