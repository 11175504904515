import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Table } from '@mui/material'
import { useState } from 'react'
import { FaBullseye } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { Edit } from '../../firebase/firebasemethod'
import './style.css'


const Details = () => {

    const location = useLocation()
    const [data, setData] = useState(location.state)
    const [val, setval] = useState('')
    console.log(location.state)

    const obj = {
        status: val,
    }

    const updateStatus = () => {
        Edit(obj, "ordersNew", data.id).then(() => {
            alert("Data is successfully updated");
            window.location.reload()
        }).catch((err) => {
            alert(err);
        })
    }

    console.log(val)

    return (
        // <div>
        //     <div style={{ border: '1px solid black' }}>
        //         <div style={{ backgroundColor: 'lightgray', padding: 5 }}>
        //             <h2>Order Details</h2>
        //         </div>
        //         <div style={{ backgroundColor: 'white', paddingTop: 10, marginLeft: 10 }}>
        //             <p style={{ fontSize: 18 }}>Order Number: {data.orderid}</p>
        //             <p style={{ fontSize: 18 }}>Order Date: {data.date}</p>
        //         </div>
        //     </div>
        //     <div style={{ border: '1px solid black', marginTop: 20 }}>
        //         <div style={{ backgroundColor: 'lightgray', padding: 5 }}>
        //             <h2>Shipping Information</h2>
        //         </div>
        //         <div style={{ backgroundColor: 'white', paddingTop: 10, marginLeft: 10 }}>  <p style={{ fontSize: 18, marginTop: 5 }}>Name: {data.firstname }{ data.lastname? data.lastname:null}</p>
        //             <p style={{ fontSize: 18, marginTop: 5 }}>Shipping Address: {data.address}</p>
        //             <p style={{ fontSize: 18, marginTop: 8 }}>City: {data.city}</p>
        //             <p style={{ fontSize: 18, marginTop: 8 }}>Province: {data.province}</p>
        //             <p style={{ fontSize: 18, marginTop: 8 }}>Country: {data.country}</p>
        //             <p style={{ fontSize: 18, marginTop: 8 }}>Zip Code: {data.zipcode}</p>
        //             <p style={{ fontSize: 18, marginTop: 8 }}>Email: {data.email}</p>
        //             <p style={{ fontSize: 18, marginTop: 8 }}>Number 1: {data.number1}</p>
        //             <p style={{ fontSize: 18, marginTop: 8 }}>Number 2: {data.number2}</p>
        //             <p style={{ fontSize: 18, marginTop: 8 }}>Remarks: {data.remarks}</p>
        //         </div>
        //     </div>
        //     <div style={{ border: '1px solid black', marginTop: 20 }}>
        //         <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'lightgray' }}>
        //             <div style={{ padding: 5 }}>
        //                 <h2>Product</h2>
        //             </div>
        //             <div>
        //                 <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        //                     <h2 style={{ paddingRight: 40 }}>Status</h2>
        //                     <h2 style={{ paddingRight: 40 }}>Qty</h2>
        //                     <h2 style={{ paddingRight: 40 }}>Each</h2>
        //                     <h2 style={{ paddingRight: 40 }}>Total</h2>
        //                 </div>
        //             </div>
        //         </div>
        //         {data.orderDetails.map((e, i) => {
        //             return (
        //                 <div style={{ backgroundColor: 'white', paddingTop: 10, marginLeft: 10 }}>
        //                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        //                         <div style={{ display: 'flex' }}>
        //                             <img src={e.image} width='15%' />
        //                             <div style={{ display: 'block', marginTop: 30 }}>
        //                                 <p style={{ fontSize: 18, marginTop: 8 }}>Name: {e.name}</p>
        //                                 <p style={{ fontSize: 18, marginTop: 8 }}>Size: {e.size}</p>
        //                                 <p style={{ fontSize: 18, marginTop: 8 }}>Color: {e.color}</p>

        //                             </div>
        //                         </div>
        //                         <div style={{ display: 'flex' }}>
        //                             {data.status === 'pending'? (
        //                                 <p style={{ fontSize: 18, marginTop: 8, color: 'orange', paddingRight: 35 }}>Pending</p>
        //                             ) : data.status === 'manufacture'? (
        //                                 <p style={{ fontSize: 18, marginTop: 8., color: 'golden', paddingRight: 35 }}>Manufacture</p>
        //                             ) : data.status === 'cancel'? (
        //                                 <p style={{ fontSize: 18, marginTop: 8., color: 'red', paddingRight: 35 }}>Cancel</p>
        //                             ) : data.status === 'shipped'? (
        //                                 <p style={{ fontSize: 18, marginTop: 8., color: 'skyblue', paddingRight: 35 }}>Shipped</p>
        //                             ) : data.status === 'rts'? (
        //                                 <p style={{ fontSize: 18, marginTop: 8., color: 'skyblue', paddingRight: 35 }}>Ready to Ship</p>
        //                             ) : data.status === 'return'? (
        //                                 <p style={{ fontSize: 18, marginTop: 8., color: 'orange', paddingRight: 35 }}>Return</p> )
        //                                 : (
        //                                     <p style={{ fontSize: 18, marginTop: 8., color: 'green', paddingRight: 35 }}>Delivered</p> )
        //                                 }

        //                             <p style={{ fontSize: 18, marginTop: 8, paddingRight: 35 }}>{e.amount}</p>
        //                             <p style={{ fontSize: 18, marginTop: 8, paddingRight: 35 }}>Rs:{e.price}</p>
        //                             <p style={{ fontSize: 18, marginTop: 8, paddingRight: 35 }}>Rs:{e.amount * e.price}</p>
        //                         </div>
        //                     </div>

        //                 </div>
        //             )
        //         })}
        //         <hr />
        //         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        //             <div>
        //                 <select onChange={(e)=>setval(e.target.value)}>
        //                     <option value={'pending'}>Pending</option>
        //                     <option value={'manufacture'}>Manufacture</option>
        //                     <option value={'cancel'}>Cancel</option>
        //                     <option value={'shipped'}>Shipped</option>
        //                     <option value={'rts'}>Ready to Ship</option>
        //                     <option value={'return'}>Return</option>
        //                     <option value={'delivered'}>Delivered</option>
        //                 </select>
        //                 <Button onClick={updateStatus}  sx={{ fontSize: 13, backgroundColor: '#926c15', color: 'black', marginLeft: 5 }}>Status Update</Button>
        //             </div>
        //             <div>

        //                 <p style={{ fontSize: 18, marginTop: 8, marginLeft: 20, paddingRight: 48 }}>Shipping: Rs:250</p>
        //                 <p style={{ fontSize: 18, marginTop: 8, marginLeft: 20, paddingRight: 48 }}>Discount: {data.discountRupees}</p>
        //                 <p style={{ fontSize: 18, marginTop: 8, marginLeft: 20, paddingRight: 48 }}>Order Total: Rs:{data.totalamount}</p>
        //             </div>
        //         </div>
        //     </div>
        // </div>




        <Box sx={{ flexGrow: 1 }}>
            <h3>Order #Id <span style={{ color: 'rgb(122, 122, 122)' }}>{data.orderid}</span> </h3>
            <Grid container spacing={2}>
                <Grid item lg={8} md={8} sm={12} sx={12}>
                    <div className='tableDiv'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Item Summary</th>

                                    <th>Qty</th>
                                    <th>Price</th>
                                    <th>Total</th>

                                </tr>
                            </thead>


                            <tbody>
                                {data.orderDetails.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td style={{ display: 'flex', alignItems: 'center', margin: 5, padding: 0 }}> <img src={e.image} width='30%' />
                                                <div className='productDetail'>
                                                    <span>{e.name}</span> <span>{e.color}</span> <span>{e.size}</span>
                                                </div>
                                            </td>

                                            <td>x{e.amount}</td>
                                            <td>{e.price}</td>
                                            <td>{e.amount * e.price}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>


                    <div style={{ padding: 20, border: '1px solid gray', backgroundColor: 'white' }}>
                        <div style={{ fontSize: 20 }}>
                            <p className='headstyle'>Customer And Order Details</p>
                        </div>
                        < hr className='hrline' />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                            <div ><p className='headstyle'>Customer Name </p></div>
                            <div><p>{data.lastname ? data.firstname + data.lastname : data.firstname}</p></div>
                        </div>


                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                            <div><p className='headstyle'>Customer Number </p></div>
                            <div><p>{data.number1}</p></div>
                        </div>



                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                            <div><p className='headstyle'>City</p></div>
                            <div><p>{data.city}</p></div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                            <div><p className='headstyle'>Status</p></div>
                            <div>
                                {data.status === 'pending' ? (
                                    <p style={{ color: 'orange' }}>Pending</p>
                                ) : data.status === 'manufacture' ? (
                                    <p style={{ color: 'golden', }}>Manufacture</p>
                                ) : data.status === 'cancel' ? (
                                    <p style={{ color: 'red' }}>Cancel</p>
                                ) : data.status === 'shipped' ? (
                                    <p style={{ color: 'skyblue' }}>Shipped</p>
                                ) : data.status === 'rts' ? (
                                    <p style={{ color: 'blue' }}>Ready to Ship</p>
                                ) : data.status === 'return' ? (
                                    <p style={{ color: 'orange' }}>Return</p>)
                                    : (
                                        <p style={{ color: 'green' }}>Delivered</p>)
                                }


                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                            <div><p className='headstyle'>Remarks</p></div>
                            <div><p>{data.remarks ? data.remarks : 'N/A'}</p></div>
                        </div>



                    </div>

                </Grid>
                <Grid style={{marginTop:10}} item lg={4} md={4} sm={12} sx={12}>
                    <div style={{ padding: 20, border: '1px solid gray', backgroundColor: 'white', marginTop: 30 }}>
                        <div>
                            <div>
                                <p style={{ fontSize: 20 }} className='headstyle'>Order Summary</p>
                            </div>

                            <hr className='hrline' />

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                                <div><p className='headstyle'>Order Created</p></div>
                                <div><p>{data.date}</p></div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                                <div><p className='headstyle'>Discount</p></div>
                                <div><p>{data.discountRupees ? data.discountRupees : 0}</p></div>
                            </div>


                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                                <div><p className='headstyle'>Delivery Charges</p></div>
                                <div><p>250</p></div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                                <div><p className='headstyle'>Total</p></div>
                                <div><p>{data.totalamount}</p></div>
                            </div>


                        </div>
                    </div>




                    <div style={{ padding: 20, border: '1px solid gray', backgroundColor: 'white', marginTop: 30 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                            <div>
                            <select style={{padding:10, borderRadius:10}} onChange={(e)=>setval(e.target.value)}>
                       <option value={'pending'}>Pending</option>
                       <option value={'manufacture'}>Manufacture</option>
                       <option value={'cancel'}>Cancel</option>
                       <option value={'shipped'}>Shipped</option>
                       <option value={'rts'}>Ready to Ship</option>
                       <option value={'return'}>Return</option>
                       <option value={'delivered'}>Delivered</option>
                   </select>


                            </div>
                            <div>
                                <Button className='updateButton' onClick={updateStatus} sx={{ fontSize: 13, backgroundColor: '#926c15', color: 'white', marginLeft: 5, borderRadius:2 }}>Status Update</Button>
                            </div>
                        </div>
                    </div>

                    

                    <div style={{ padding: 20, border: '1px solid gray', backgroundColor: 'white', marginTop: 30 }}>

                        <div>
                                <p style={{ fontSize: 20 }} className='headstyle'>Delivery Address</p>
                            </div>

                            <hr className='hrline' />

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                                <div><p className='headstyle'>Address: </p></div>
                                <div><p style={{marginLeft:25}}> { data.address}</p></div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                                <div><p className='headstyle'>City: </p></div>
                                <div><p> { data.city}</p></div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                                <div><p className='headstyle'>Province: </p></div>
                                <div><p> { data.province? data.provice : data.city}</p></div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                                <div><p className='headstyle'>Country: </p></div>
                                <div><p> { data.country? data.country :'Pakistan'}</p></div>
                            </div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}




export default Details
