import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getData } from "../../firebase/firebasemethod";

function ColumnChart (){
  const [chart, setChart] = useState()
  const [data,setData] = useState([])

  React.useEffect(() => {
        getData('ordersNew')
            .then((res) => {
                setData(Object.values(res))
                // console.log(res);
                // setLoading(false)
            })
            .catch((err) => {
                alert(err);
                // setLoading(false)
            })
    }, []);
  

    // Week Filter 

//     function getWeekDates() {

//   let now = new Date();
//   let dayOfWeek = now.getDay(); //0-6
//   let numDay = now.getDate();
//   let hours =now.getHours();

//   let start = new Date(); //copy
//   start.setDate(numDay - dayOfWeek+3);



//   let end = new Date(); //copy
//   end.setDate(numDay + ( dayOfWeek - 9));

//   return [start, end];
// }

// let [start, end] = getWeekDates();

// console.log(start.toLocaleString(), end.toLocaleString());

// const dateWeek = {
//     start: start.toLocaleString(),
//     end:   end.toLocaleString()
// }

const date = new Date();


// Days Filter 
let todayDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;

const todayOrders = data.filter((item) => {
    return item.date === todayDate ;
})




const day1ago = new Date();

const dayAgo1 = day1ago.setDate(day1ago.getDate() - 1)

let day1 = day1ago.getDate();
let month1 = day1ago.getMonth();
let year1 = day1ago.getFullYear();

let days1Ago = `${year1}-${month1+1}-${day1}`;

const yesterdaOrders = data.filter((item) => {
    return item.date === days1Ago ;
})


// Week Filter


const startDate = new Date();
let dateStart = startDate.getDate();
let startMonth = startDate.getMonth() + 1;
let startYear = startDate.getFullYear();

let startDate2 = new Date(startYear, startMonth - 1, dateStart); // Create a new Date object

// console.log(startDate2.toLocaleDateString());


const endDate = new Date();

endDate.setDate(endDate.getDate() - 7);

let dateEnd = endDate.getDate();
let endMonth = endDate.getMonth() + 1; // Months are zero-indexed, so add 1
let endYear = endDate.getFullYear();

let endDate2 = new Date(endYear, endMonth - 1, dateEnd);



// console.log(endDate2.toLocaleDateString(),startDate2);





const weekOrders = data.filter((item) => {
  const itemDate = new Date(item.date); // Convert item.date to a Date object
  // console.log(itemDate);
  return itemDate >= endDate2 && itemDate <= startDate2; // Compare Date objects
});


// console.log(weekOrders)






const startDate222 = new Date();

startDate222.setDate(startDate222.getDate() - 8)

let dateStart2 = startDate222.getDate();
let startMonth2 = startDate222.getMonth() +1;
let startYear2 = startDate222.getFullYear();

let startDate22 = new Date(startYear2, startMonth2 - 1, dateStart2);


const endDate122 = new Date();

 endDate122.setDate(endDate122.getDate() - 13)

let dateend2 = endDate122.getDate();
let endMonth2 = endDate122.getMonth();
let endYear2 = endDate122.getFullYear();

let endDate22 = new Date(endYear2, endMonth2 - 1, dateend2);



// console.log(endDate2,startDate2);

// console.log(startDate,endDate);



const weekOrdersLast = data.filter((item) => {
  const itemDate = new Date(item.date); // Convert item.date to a Date object
  // console.log(itemDate);
  return itemDate >= endDate22 && itemDate <= startDate22; // Compare Date objects
});


// console.log(weekOrdersLast)








// Month Filter


const monthStart = new Date();

let monthdateStart = monthStart.getDate();
let monthstartMonth = monthStart.getMonth() + 1;
let monthstartYear = monthStart.getFullYear();

let startDateMonth = new Date(monthstartYear, monthstartMonth - 1, monthdateStart); // Create a new Date object

// console.log(startDate2.toLocaleDateString());


const endDateMonth = new Date();

endDate.setDate(endDate.getDate() - 29);

let dateEndMonth = endDate.getDate();
let monthendMonth = endDate.getMonth() + 1; // Months are zero-indexed, so add 1
let monthendYear = endDate.getFullYear();

let monthendDate = new Date(monthendYear, monthendMonth - 1, dateEndMonth);








const monthOrders = data.filter((item) => {
  const itemDate = new Date(item.date); // Convert item.date to a Date object
  // console.log(itemDate);
  return itemDate >= monthendDate && itemDate <= endDateMonth; // Compare Date objects
});


// console.log(weekOrders)





const lastmonthStart = new Date();

lastmonthStart.setDate(lastmonthStart.getDate() - 30);

let lastmonthdateStart = lastmonthStart.getDate();
let lastmonthstartMonth = lastmonthStart.getMonth() + 1;
let lastmonthstartYear = lastmonthStart.getFullYear();

let laststartDateMonth = new Date(lastmonthstartYear, lastmonthstartMonth - 1, lastmonthdateStart); // Create a new Date object

// console.log(startDate2.toLocaleDateString());


const lastendDateMonth = new Date();

lastendDateMonth.setDate(lastendDateMonth.getDate() - 59);

let lastdateEndMonth = lastendDateMonth.getDate();
let lastmonthendMonth = lastendDateMonth.getMonth() + 1; // Months are zero-indexed, so add 1
let lastmonthendYear = lastendDateMonth.getFullYear();

let lastmonthendDate = new Date(lastmonthendYear, lastmonthendMonth - 1, lastdateEndMonth);








const lastMonthOrders = data.filter((item) => {
  const itemDate = new Date(item.date); // Convert item.date to a Date object
  // console.log(itemDate);
  return itemDate >= lastmonthendDate && itemDate <= laststartDateMonth; // Compare Date objects
});


// console.log(weekOrders)

// console.log(lastMonthOrders)





// Year Filter


const yearStart = new Date();

let yeardateStart = yearStart.getDate();
let yearstartyear = yearStart.getMonth() + 1;
let yearstartYear = yearStart.getFullYear();

let startDateyear = new Date(yearstartYear, yearstartyear - 1, yeardateStart); // Create a new Date object

// console.log(startDate2.toLocaleDateString());


const endDateyear = new Date();

endDate.setDate(endDate.getDate() - 365);

let dateEndyear = endDate.getDate();
let yearendyear = endDate.getMonth() + 1; // years are zero-indexed, so add 1
let yearendYear = endDate.getFullYear();

let yearendDate = new Date(yearendYear, yearendyear - 1, dateEndyear);








const YearOrders = data.filter((item) => {
  const itemDate = new Date(item.date); // Convert item.date to a Date object
  // console.log(itemDate);
  return itemDate >= yearendDate && itemDate <= endDateyear; // Compare Date objects
});


// console.log(weekOrders)




















    const  columnData = {
          
            series: [{
              name: 'This',
              data: [todayOrders.length, weekOrders.length, monthOrders.length,  ]
            }, {
              name: 'Last',
              data: [yesterdaOrders.length, weekOrdersLast.length, lastMonthOrders.length,]
            }],
            options: {
              chart: {
                type: 'bar',
                height: 350
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded'
                },
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              xaxis: {
                categories: ['Day', '7 Days', '30 Days'],
              },
              yaxis: {
                title: {
                  text: 'Sales'
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                    formatter: function (val) {
                    return  val + " Orders"
                  }
                }
              }
            },
          
          
          };
          return (
            <div>
              <div className='box-shadow' style={{marginTop:20, backgroundColor:'white'}} id="chart">
                <ReactApexChart options={columnData.options} series={columnData.series} type="bar" height={350} />
              </div>
              <div id="html-dist"></div>
            </div>
          );
        }

      


export default ColumnChart