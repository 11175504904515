import { Box, Button, CircularProgress, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getData } from "../../firebase/firebasemethod";
import GradingIcon from '@mui/icons-material/Grading';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from "react-router-dom";
import Chart from "./Chart";
import './style.css'





function Dashboard() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [amount, setAmount] = useState('address')
    const [dateTo, setDateTo] = useState()
    const [dateFrom, setDateFrom] = useState()
    const [chart, setChart] = useState()
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [timeout, setTime] = useState(true)
    const navigate = useNavigate()

    setTimeout(() => {
        setTime(false)
    }, 4000);



    useEffect(() => {
        getData('ordersNew')
            .then((res) => {
                setData(Object.values(res))
                console.log(res);
                setLoading(false)
            })
            .catch((err) => {
                alert(err);
                setLoading(false)
            })
    }, []);

    const amountInform = data.filter((item) => {
        return amount === '' ? item :
            item.totalamount;
    })

    const totalAmount = amountInform.reduce((acc, curr) => {
        return acc + curr.totalamount
    }, 0)





    const pendingOrders = data.filter((item) => {
        return amount === '' ? item :
            item.status == 'pending';
    })

    const pendingInform = pendingOrders.filter((item) => {
        return amount === '' ? item :
            item.totalamount;
    })

    const pendingAmount = pendingInform.reduce((acc, curr) => {
        return acc + curr.totalamount
    }, 0)





    const deliverOrders = data.filter((item) => {
        return amount === '' ? item :
            item.status == 'delivered';
    })

    const deliverInform = deliverOrders.filter((item) => {
        return amount === '' ? item :
            item.totalamount;
    })

    const deliverAmount = deliverInform.reduce((acc, curr) => {
        return acc + curr.totalamount
    }, 0)







    const shippedOrders = data.filter((item) => {
        return amount === '' ? item :
            item.status == 'shipped';
    })

    const shippedInform = shippedOrders.filter((item) => {
        return amount === '' ? item :
            item.totalamount;
    })

    const shippedAmount = shippedInform.reduce((acc, curr) => {
        return acc + curr.totalamount
    }, 0)





    const cancelOrders = data.filter((item) => {
        return amount === '' ? item :
            item.status == 'cancel';
    })

    const cancelInform = cancelOrders.filter((item) => {
        return amount === '' ? item :
            item.totalamount;
    })

    const cancelAmount = cancelInform.reduce((acc, curr) => {
        return acc + curr.totalamount
    }, 0)






    const manufactureOrders = data.filter((item) => {
        return amount === '' ? item :
            item.status == 'manufacture';
    })

    const manufactureInform = manufactureOrders.filter((item) => {
        return amount === '' ? item :
            item.totalamount;
    })

    const manufactureAmount = manufactureInform.reduce((acc, curr) => {
        return acc + curr.totalamount
    }, 0)





    const rtsOrders = data.filter((item) => {
        return amount === '' ? item :
            item.status == 'rts';
    })

    const rtsInform = rtsOrders.filter((item) => {
        return amount === '' ? item :
            item.totalamount;
    })

    const rtsAmount = rtsInform.reduce((acc, curr) => {
        return acc + curr.totalamount
    }, 0)






    const returnOrders = data.filter((item) => {
        return amount === '' ? item :
            item.status == 'return';
    })

    const returnInform = returnOrders.filter((item) => {
        return amount === '' ? item :
            item.totalamount;
    })

    const returnAmount = returnInform.reduce((acc, curr) => {
        return acc + curr.totalamount
    }, 0)



    // const dateFilter = data.filter((item) => {
    //     return dateTo === '' ? item :
    //         item.date == dateTo || item.date == dateFrom;
    // })



    // const render = () =>{

    //     const finder = data.filter((r)=> r.date == date)
    //     console.log(finder)
    //     if(finder.length === 0){ 
    //     alert('This Date Data is Not Avialable')
    //     }else{

    //     }
    //   }


    const changeFormattotalAmount = new Intl.NumberFormat().format(totalAmount)
    const changeFormatPendingAmt = new Intl.NumberFormat().format(pendingAmount)
    const changeFormatReturnAmt = new Intl.NumberFormat().format(returnAmount)
    const changeFormatCancelAmt = new Intl.NumberFormat().format(cancelAmount)
    const changeFormatRtsAmt = new Intl.NumberFormat().format(rtsAmount)
    const changeFormatShippedAmt = new Intl.NumberFormat().format(shippedAmount)
    const changeFormatDeliverAmt = new Intl.NumberFormat().format(deliverAmount)
    const changeFormatManuAmt = new Intl.NumberFormat().format(manufactureAmount)




    const date = new Date();


    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();

    let currentDate = `${year}-${month + 1}-${day}`;

    const todayOrders = data.filter((item) => {
        return setChart === '' ? item :
            item.date === currentDate;
    })

    let yesterdayDate = `${year}-${month + 1}-${day - 1}`;

    const yesterdayOrders = data.filter((item) => {
        return setChart === '' ? item :
            item.date === yesterdayDate;
    })








    const topSales = [];
    const topProduct = []

    data?.forEach(order => {
        order?.orderDetails?.forEach(detail => {
            topSales.push(detail.name);
        });
    });

    const topProductSales = topSales.reduce((acc, name) => {
        acc[name] = (acc[name] || 0) + 1;
        // topProduct.push(topProductSales)
        return acc;
    }, {});

    const topSalesName = topProduct.push(topProductSales)

    console.log(topProductSales); // { "productA": 2, "productB": 2, "productC": 1 }



    const sortedProductSales = Object.entries(topProductSales).sort((a, b) => b[1] - a[1]);

    // Extract the top five products
    const topFiveProducts = sortedProductSales.slice(0, 5);

    console.log('Top Five Products:', topFiveProducts);







    // Date Filter 





    return (
        <>
            <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>

                    <h2>Dashboard</h2>
                </div>

                {/* <div style={{display:'flex'}}>
                <TextField type="date" onChange={(e) => setDateTo(e.target.value)}/> 
                <TextField type="date" onChange={(e) => setDateFrom(e.target.value)}/> 
                <Button sx={{mt:2}} variant="outlined" onClick={dateFilter}>Search</Button>
                </div> */}
            </div>
            <div >


                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={4} sm={6} xs={6} >
                            <div className='box-shadow' style={{ backgroundColor: 'white', borderRadius: 20, }}>
                                <div style={{ display: 'flex', justifyContent: 'center', padding: 15, textAlign: 'center' }}>



                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} md={5} sm={6} xs={6}>
                                                <div style={{ marginTop: '30%' }}>
                                                    <GradingIcon style={{ fontSize: '250%', color: 'blue' }} />
                                                </div>
                                            </Grid>
                                            <Grid item lg={8} md={7} sm={6} xs={6}>
                                                <div >
                                                    <p>Total Orders</p>
                                                    <h1>{data.length ? data.length : <CircularProgress />}</h1>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>



                                </div>
                                <hr />
                                <div style={{ textAlign: 'center', padding: 10, color: 'gray', display: 'flex', justifyContent: 'space-around' }}>
                                    <p>PKR: {changeFormattotalAmount} </p>
                                    <p>{todayOrders.length >= yesterdayOrders.length ? <TrendingUpIcon style={{ color: 'green' }} /> : <TrendingDownIcon style={{ color: 'red' }} />}</p>
                                </div>
                            </div>
                        </Grid>





                        <Grid item lg={4} md={4} sm={6} xs={6} >
                            <div className='box-shadow' style={{ backgroundColor: 'white', borderRadius: 20, }}>

                                <div style={{ display: 'flex', justifyContent: 'center', padding: 15, textAlign: 'center' }}>



                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} md={5} sm={6} xs={6}>
                                                <div style={{ marginTop: '30%' }}>
                                                    <PendingActionsIcon style={{ fontSize: '250%', color: '#eeba0b' }} />
                                                </div>
                                            </Grid>
                                            <Grid item lg={8} md={7} sm={6} xs={6}>
                                                <div >
                                                    <p>Pending Orders</p>
                                                    <h1>{pendingOrders.length ? pendingOrders.length : timeout ? <CircularProgress /> : 0}</h1>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>



                                </div>
                                <hr />
                                <div style={{ textAlign: 'center', padding: 10, color: 'gray' }}>
                                    <p>PKR: {changeFormatPendingAmt}</p>
                                </div>
                            </div>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={6} >
                            <div className='box-shadow' style={{ backgroundColor: 'white', borderRadius: 20, }}>

                                <div style={{ display: 'flex', justifyContent: 'center', padding: 15, textAlign: 'center' }}>



                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} md={5} sm={6} xs={6}>
                                                <div style={{ marginTop: '30%' }}>
                                                    <WarehouseIcon style={{ fontSize: '250%', color: 'brown' }} />
                                                </div>
                                            </Grid>
                                            <Grid item lg={8} md={7} sm={6} xs={6}>
                                                <div >
                                                    <p>Manufacture Orders</p>
                                                    <h1>{manufactureOrders.length ? manufactureOrders.length : timeout ? <CircularProgress /> : 0}</h1>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>



                                </div>
                                <hr />
                                <div style={{ textAlign: 'center', padding: 10, color: 'gray' }}>
                                    <p>PKR: {changeFormatManuAmt}</p>
                                </div>
                            </div>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={6} >
                            <div className='box-shadow' style={{ backgroundColor: 'white', borderRadius: 20, }}>

                                <div style={{ display: 'flex', justifyContent: 'center', padding: 15, textAlign: 'center' }}>



                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} md={5} sm={6} xs={6}>
                                                <div style={{ marginTop: '30%' }}>
                                                    <CheckBoxIcon style={{ fontSize: '250%', color: 'skyblue' }} />
                                                </div>
                                            </Grid>
                                            <Grid item lg={8} md={7} sm={6} xs={6}>
                                                <div >
                                                    <p>Ready to Ship</p>
                                                    <h1>{rtsOrders.length ? rtsOrders.length : timeout ? <CircularProgress /> : 0}</h1>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>



                                </div>
                                <hr />
                                <div style={{ textAlign: 'center', padding: 10, color: 'gray' }}>
                                    <p>PKR: {changeFormatRtsAmt}</p>
                                </div>
                            </div>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={6} >
                            <div className='box-shadow' style={{ backgroundColor: 'white', borderRadius: 20, }}>

                                <div style={{ display: 'flex', justifyContent: 'center', padding: 15, textAlign: 'center' }}>



                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} md={5} sm={6} xs={6}>
                                                <div style={{ marginTop: '30%' }}>
                                                    <LocalShippingIcon style={{ fontSize: '250%', color: 'purple' }} />
                                                </div>
                                            </Grid>
                                            <Grid item lg={8} md={7} sm={6} xs={6}>
                                                <div >
                                                    <p>Shipped Orders</p>
                                                    <h1>{shippedOrders.length ? shippedOrders.length : timeout ? <CircularProgress /> : 0}</h1>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>



                                </div>
                                <hr />
                                <div style={{ textAlign: 'center', padding: 10, color: 'gray' }}>
                                    <p>PKR: {changeFormatShippedAmt}</p>
                                </div>
                            </div>
                        </Grid>


                        <Grid item lg={4} md={4} sm={6} xs={6} >
                            <div className='box-shadow' style={{ backgroundColor: 'white', borderRadius: 20, }}>

                                <div style={{ display: 'flex', justifyContent: 'center', padding: 15, textAlign: 'center' }}>



                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} md={5} sm={6} xs={6}>
                                                <div style={{ marginTop: '30%' }}>
                                                    <WhereToVoteIcon style={{ fontSize: '250%', color: 'green' }} />
                                                </div>
                                            </Grid>
                                            <Grid item lg={8} md={7} sm={6} xs={6}>
                                                <div >
                                                    <p>Delivered Orders</p>
                                                    <h1>{deliverOrders.length ? deliverOrders.length : timeout ? <CircularProgress /> : 0}</h1>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>



                                </div>
                                <hr />
                                <div style={{ textAlign: 'center', padding: 10, color: 'gray' }}>
                                    <p>PKR: {changeFormatDeliverAmt}</p>
                                </div>
                            </div>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={6} >
                            <div className='box-shadow' style={{ backgroundColor: 'white', borderRadius: 20, }}>

                                <div style={{ display: 'flex', justifyContent: 'center', padding: 15, textAlign: 'center' }}>



                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} md={5} sm={6} xs={6}>
                                                <div style={{ marginTop: '30%' }}>
                                                    <KeyboardReturnIcon style={{ fontSize: '250%', color: 'maroon' }} />
                                                </div>
                                            </Grid>
                                            <Grid item lg={8} md={7} sm={6} xs={6}>
                                                <div >
                                                    <p>Return Orders</p>
                                                    <h1>{returnOrders.length ? returnOrders.length : timeout ? <CircularProgress /> : 0}</h1>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>



                                </div>
                                <hr />
                                <div style={{ textAlign: 'center', padding: 10, color: 'gray' }}>
                                    <p>PKR: {changeFormatReturnAmt}</p>
                                </div>
                            </div>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={6} >
                            <div className='box-shadow' style={{ backgroundColor: 'white', borderRadius: 20, }}>

                                <div style={{ display: 'flex', justifyContent: 'center', padding: 15, textAlign: 'center' }}>



                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} md={5} sm={6} xs={6}>
                                                <div style={{ marginTop: '30%' }}>
                                                    <CancelIcon style={{ fontSize: '250%', color: 'red' }} />
                                                </div>
                                            </Grid>
                                            <Grid item lg={8} md={7} sm={6} xs={6}>
                                                <div >
                                                    <p>Cancel Orders</p>
                                                    <h1>{cancelOrders.length ? cancelOrders.length : timeout ? <CircularProgress /> : 0}</h1>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>



                                </div>
                                <hr />
                                <div style={{ textAlign: 'center', padding: 10, color: 'gray' }}>
                                    <p>PKR: {changeFormatCancelAmt}</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>

                <div>
              
                </div>

               
            </div>

        </>
    )
}

export default Dashboard