import { Button, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Edit } from "../../firebase/firebasemethod";
import Base_Url from "../../url";
import { FormControl } from "react-bootstrap";


function EditProduct() {
    let location = useLocation();
    // console.log(location.state);
    let [product, setProduct] = useState(location.state);
    const [category, setCategory] = useState('')

    const obj = {
        category: category,
    }



    const updateCategory = () => {
        if(category == ''){
            alert('Please Fill Category')
            return;
        }else{
            Edit(obj, "Products", product.pId).then(() => {
            }).catch((err) => {
                alert(err);
            })

            Edit(product, "Products", product.pId).then(() => {
                alert("Data is successfully updated");
            }).catch(() => {
                alert("Something is wrong");
            })
        }
        
    }

    

   

    

    const updateDetails = () => {
        updateCategory()
    }


    console.log(category)

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input fullWidth placeholder='Product Name' variant='standard' value={product.name} onChange={e => setProduct({ ...product, name: e.target.value, category: category })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input fullWidth placeholder='Product Price' variant='standard' value={product.price} onChange={e => setProduct({ ...product, price: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input fullWidth placeholder='Product Special Price' variant='standard' value={product.sPrice} onChange={e => setProduct({ ...product, sPrice: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input fullWidth placeholder='Product Description' value={product.desc} variant='standard' onChange={e => setProduct({ ...product, desc: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input fullWidth placeholder='Product Stock' value={product.stock} variant='standard' onChange={e => setProduct({ ...product, stock: e.target.value })} />
                    </Grid>
                    <Grid style={{ marginTop: 20 }} item xs={12} sm={12} md={4} lg={4}>
                                            
                            <select required style={{padding:10, borderRadius:10}} onChange={(e)=>setCategory(e.target.value)}>
                       <option value={''}>Select Category</option>
                       <option value={'KufiHats'}>Kufi Hats</option>
                       <option value={'Miswak'}>Miswak</option>
                   </select>

                       


                    </Grid>

                </Grid>
            </Box>
            <button style={{ marginTop: 10, padding: 10, background: 'yellow', color: 'black', border: 'none', fontSize: 13 }} onClick={updateDetails}>Update data</button>
        </>
    )
};


export default EditProduct;