// import { Container } from "@mui/system";
// import { useState } from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import './review.css'
// import { getData } from "../firebase/firebasemethod";
// import { FaStar, FaStarHalfAlt } from "react-icons/fa";
// import {AiOutlineStar} from 'react-icons/ai'
// import Star from "./Star";

// const ReviewSlider = ()=>{
//     const [data, setData] = useState([]);

//     const Data = ()=>{
//         getData('Review').then((res)=>{
//             // console.log(res.rating)
//             setData(res)
//         }).catch((err)=>{
//             // console.log(err)
//         })
//     }
    
//     Data();

//     // console.log(data)

  

//     const responsive = {
//         superLargeDesktop: {
//           // the naming can be any, depends on you.
//           breakpoint: { max: 4000, min: 3000 },
//           items: 5
//         },
//         desktop: {
//           breakpoint: { max: 3000, min: 1024 },
//           items: 3
//         },
//         tablet: {
//           breakpoint: { max: 1024, min: 464 },
//           items: 2
//         },
//         mobile: {
//           breakpoint: { max: 464, min: 0 },
//           items: 1
//         }
//       };

// return(
//     <div style={{backgroundColor:'#f8f9fa',padding:'15px',marginTop:'20px'}}>
//         <h1 style={{fontWeight:'bold',textAlign:'center',marginTop:'2%'}}>Customer Reviews</h1>
//     <Carousel style={{display:'flex',flexDirection:'row-reverse'}} responsive={responsive}>
      
//   {data.map((e,i)=>{
//       return(
//         <Container>
//           <div style={{}}>
//         <div key={i} style={{backgroundColor:'#fff',display:'flex',flexDirection:'row-reverse',marginTop:'5%', height:'40vh',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
//         <h2 style={{textAlign:'center',fontSize:35}}>{e.name}</h2>
//             <Star style={{textAlign:'center'}} star={e.star}/>
//         <h3 style={{textAlign:'center',fontSize:'20px'}}>{e.reviewComment}</h3>
//     </div>
//     </div>
//         </Container>
//         )
//       })}
      
// </Carousel>

// <div className="taggbox" style="width:100%;height:100%" data-widget-id="148850" data-tags="false" ></div><script src="https://widget.taggbox.com/embed-lite.min.js" type="text/javascript"></script>
//         </div>
//     )
// }; 

// export default ReviewSlider


import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components'
import './slider.css'


const TaggboxWidget = (props) => {
  const {img_link} = props
  

  return(
    <Wrapper >
      <Box className='services-1'>
    <Container>
    <Box  sx={{ flexGrow: 1 }}>
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
      <img src={img_link} width='100%'/>
      </Grid>
    </Grid>
  </Box>
  </Container>
  </Box>
  </Wrapper>
  )
}
  
 

export default TaggboxWidget;


const Wrapper = styled.section`
  padding: 3rem 0;
  .grid {
    gap: 4.8rem;
  }
  .background{
    background: ${({ theme }) => theme.colors.bg};
    // border-radius: 2rem;
    // height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


   
  }
  
  .services-1,
  .services-2,
  .services-3 {
    width: auto;
    // height: 55rem;
    padding:70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: ${({ theme }) => theme.colors.bg};
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }`