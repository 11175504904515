import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import AddProduct from './DScreen/addProduct';
import AddProductHome from './DScreen/AddProductHome';
import EditProduct from './DScreen/EditProduct';
import Details from './DScreen/details';
import Orders from './DScreen/feedback';
import Review from './DScreen/review';
import Coupon from './DScreen/coupon';
import CouponHome from './DScreen/couponHome';
import EditCoupon from './DScreen/editCoupon';
import Dashboard from './DScreen/dashboard';
import Chart from './DScreen/Chart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StoreIcon from '@mui/icons-material/Store';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import modeloim from '../images/logo.png';
import AddNewProduct from './DScreen/addProductNew';
import AddCoupon from './DScreen/addCoupon';
import Protected from './DScreen/protected';
const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const navigate = useNavigate()
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    
    <div>
      {}
       <a style={{display:'flex',justifyContent:'center',marginBottom:-40,marginTop:20}} href="/" className="brand-name">
        <img width='100px' src={modeloim} alt="" />
      </a>
      <Toolbar />
      <List>
        {[
           {
            route: '/a5fgh4-0565-hgseu-4s5as-s54s54d5sh5ggs',
            name: 'Dashboard',
            icon: <DashboardIcon />
           
          },
          {
          route: 'orders',
          name: 'Orders',
          icon: <LocalMallIcon />
         
        },
          {
          route: 'Product-Details',
          name: 'Products',
          icon: <StoreIcon />
         
        },
         
        {
          route: 'coupon',
          name: 'Coupons',
          icon: <LocalOfferIcon />
         
        },
        {
          route: 'sales-anaylatics',
          name: 'Sales Anaylatics',
          icon: <AnalyticsIcon />
         
        },
          
      ].map((text, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={()=>{
              navigate (text.route)
              
            }}>
              <ListItemIcon>
                {text.icon}
              </ListItemIcon>
              <ListItemText primary={text.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      
    
    </div>
  );




  

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      
      <CssBaseline />
      
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        
        <Toolbar style={{backgroundColor:'white'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography  variant="h4" color='black' noWrap component="div">
            Admin Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        style={{backgroundColor:'#f8f9fa'}}
      >
        <Toolbar />
        <Routes>
          <Route path='/orders/' element={<Protected Component={Orders} /> } />
            <Route path="/detail" element={<Protected Component={Details} /> } />      
          <Route path='/Product-Details/' element={<Protected Component={AddProduct} /> } >
            <Route index element={<Protected Component={AddProductHome} /> } />    
            <Route path="EditProduct" element={<Protected Component={EditProduct} /> } /> 
            <Route path="Add-Product" element={<Protected Component={AddNewProduct} />  } />      
          </Route>
         
          <Route path='/coupon/' element={<Protected Component={Coupon} /> } >
            <Route index element={<Protected Component={CouponHome} /> } />   
            <Route path="EditCoupon" element={<Protected Component={EditCoupon} /> } />  
            <Route path="Add-Coupon" element={<Protected Component={AddCoupon} /> } />  
          </Route>
            <Route path="review" element={<Protected Component={Review} /> } />      
            <Route path="/" element={<Protected Component={Dashboard} /> } /> 
            <Route path="sales-anaylatics" element={<Protected Component={Chart} />  } />
            
        </Routes>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;