import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, axisClasses } from '@mui/x-charts';

import Title from './Title';
import { getData } from '../../firebase/firebasemethod';
import ReactApexChart from 'react-apexcharts';
import ColumnChart from './columnChart';
import RevenueChart from './revenueChart';
import { Box, Grid } from '@mui/material';

// Generate Sales Data








export default function Chart() {

  const [data,setData] = React.useState([])
  const [chart, setChart] = React.useState()
  const [orders, setOrders] = React.useState([])

  React.useEffect(() => {
    getData('ordersNew')
        .then((res) => {
            setData(Object.values(res))
            console.log(res);
            // setLoading(false)
        })
        .catch((err) => {
            alert(err);
            // setLoading(false)
        })
}, []);

const date = new Date();


let day = date.getDate();
let month = date.getMonth();
let year = date.getFullYear();

let currentDate = `${year}-${month+1}-${day}`;

const chartDate = data.filter((item) => {
    return setChart === '' ? item :
        item.date === currentDate ;
})



const chartInformToday = chartDate.filter((item) => {
  return  item.totalamount;
})

const currentRevenueChart = chartInformToday.reduce((acc, curr) => {
  return acc + curr.totalamount
}, 0)




const yesterDayDate = new Date();

const yesterday = yesterDayDate.setDate(yesterDayDate.getDate() - 1)

let day1 = yesterDayDate.getDate();
let month1 = yesterDayDate.getMonth();
let year1 = yesterDayDate.getFullYear();

let yesterdayDate = `${year1}-${month1+1}-${day1}`;

const yesterdayOrders = data.filter((item) => {
    return setChart === '' ? item :
        item.date === yesterdayDate ;
})

const conversionFilter = yesterdayOrders.length / 1
console.log(conversionFilter)

const chartInformYesterday = yesterdayOrders.filter((item) => {
  return  item.totalamount;
})

const yesterdayRevenueChart = chartInformYesterday.reduce((acc, curr) => {
  return acc + curr.totalamount
}, 0)




const day2ago = new Date();

const dayAgo2 = day2ago.setDate(day2ago.getDate() - 2)

let day2 = day2ago.getDate();
let month2 = day2ago.getMonth();
let year2 = day2ago.getFullYear();

let days2Ago = `${year2}-${month2+1}-${day2}`;

const days2AgoOrders = data.filter((item) => {
    return setChart === '' ? item :
        item.date === days2Ago ;
})

const chartInformday2Ago = days2AgoOrders.filter((item) => {
  return  item.totalamount;
})

const day2AgoRevenueChart = chartInformday2Ago.reduce((acc, curr) => {
  return acc + curr.totalamount
}, 0)






const day3ago = new Date();

const dayAgo3 = day3ago.setDate(day3ago.getDate() - 3)

let day3 = day3ago.getDate();
let month3 = day3ago.getMonth();
let year3 = day3ago.getFullYear();

let days3Ago = `${year3}-${month3+1}-${day3}`;

const days3AgoOrders = data.filter((item) => {
    return setChart === '' ? item :
        item.date === days3Ago ;
})

const chartInformday3Ago = days3AgoOrders.filter((item) => {
  return  item.totalamount;
})

const day3AgoRevenueChart = chartInformday3Ago.reduce((acc, curr) => {
  return acc + curr.totalamount
}, 0)






const day4ago = new Date();

const dayAgo4 = day4ago.setDate(day4ago.getDate() - 4)

let day4 = day4ago.getDate();
let month4 = day4ago.getMonth();
let year4 = day4ago.getFullYear();

let days4Ago = `${year4}-${month4+1}-${day4}`;

const days4AgoOrders = data.filter((item) => {
    return setChart === '' ? item :
        item.date === days4Ago ;
})


const chartInformday4Ago = days4AgoOrders.filter((item) => {
  return  item.totalamount;
})

const day4AgoRevenueChart = chartInformday4Ago.reduce((acc, curr) => {
  return acc + curr.totalamount
}, 0)





const day5ago = new Date();

const dayAgo5 = day5ago.setDate(day5ago.getDate() - 5)

let day5 = day5ago.getDate();
let month5 = day5ago.getMonth();
let year5 = day5ago.getFullYear();

let days5Ago = `${year5}-${month5+1}-${day5}`;

const days5AgoOrders = data.filter((item) => {
    return setChart === '' ? item :
        item.date === days5Ago ;
})

const chartInformday5Ago = days5AgoOrders.filter((item) => {
  return  item.totalamount;
})

const day5AgoRevenueChart = chartInformday5Ago.reduce((acc, curr) => {
  return acc + curr.totalamount
}, 0)





const day6ago = new Date();

const dayAgo6 = day6ago.setDate(day6ago.getDate() - 6)

let day6 = day6ago.getDate();
let month6 = day6ago.getMonth();
let year6 = day6ago.getFullYear();

let days6Ago = `${year6}-${month6+1}-${day6}`;

const days6AgoOrders = data.filter((item) => {
    return setChart === '' ? item :
        item.date === days6Ago;
})

const chartInformday6Ago = days6AgoOrders.filter((item) => {
  return  item.totalamount;
})

const day6AgoRevenueChart = chartInformday6Ago.reduce((acc, curr) => {
  return acc + curr.totalamount
}, 0)




console.log(days2Ago)


// Top Product Sales 

const topSales = [];
const topProduct = []

data?.forEach(order => {
  order?.orderDetails?.forEach(detail => {
    topSales.push(detail.name);
  });
});

const topProductSales = topSales.reduce((acc, name) => {
  acc[name] = (acc[name] || 0) + 1;
  // topProduct.push(topProductSales)
  return acc;
}, {});

const topSalesName = topProduct.push(topProductSales)

console.log(topProductSales); // { "productA": 2, "productB": 2, "productC": 1 }



const sortedProductSales = Object.entries(topProductSales).sort((a, b) => b[1] - a[1]);

// Extract the top five products
const topFiveProducts = sortedProductSales.slice(0, 5);



console.log('Top Five Products:', topFiveProducts);

const topProductsName = []
const topProductsRank = []

const topProductData = topFiveProducts.map((e,i)=>{
  const dataObject = {
    name: e[0],
    sales: e[1]
  }

  topProductsName.push(dataObject.name)
  topProductsRank.push(dataObject.sales)

})
console.log(topProductsName)




// Top Cities 

const topCities = []
const topCityData = []

const topCitiesData = data?.filter((e,i)=>{
  topCities.push(e.city)
})

const topCitiesSales = topCities.reduce((acc, name) => {
  console.log(acc[name])
  acc[name] = (acc[name] || 0) + 1;
  // topProduct.push(topProductSales)
  return acc;
}, {});

const topCitiesName = topCityData.push(topCitiesSales)

console.log(topCityData); 


const sortedCitiesSales = Object.entries(topCitiesSales).sort((a, b) => b[1] - a[1]);

// Extract the top five Citiess
const topFiveCities = sortedCitiesSales.slice(0, 5);


console.log('Top Five Cities:', topFiveCities);

const topCitiesNameData = []
const topCitiesRank = []

const topCitiesDataName = topFiveCities.map((e,i)=>{
  const dataObject = {
    name: e[0],
    sales: e[1]
  }

  topCitiesNameData.push(dataObject.name)
  topCitiesRank.push(dataObject.sales)

})

console.log(topCitiesNameData)



//  let maxCount = 0;
// let mostFrequentName = '';

// for (const [name, count] of Object.entries(topProductSales)) {
//   if (count > maxCount) {
//     maxCount = count;
//     mostFrequentName = name;
//   }
// }

// console.log(`The most frequent name is "${mostFrequentName}" with ${maxCount} occurrences.`);




// function getWeekDates() {

//   let now = new Date();
//   let dayOfWeek = now.getDay(); //0-6
//   let numDay = now.getDate();
//   let hours =now.getHours();

//   let start = new Date(); //copy
//   start.setDate(numDay - dayOfWeek+2);



//   let end = new Date(); //copy
//   end.setDate(numDay + ( dayOfWeek - 3));

//   return [start, end];
// }

// let [start, end] = getWeekDates();

// console.log(start.toLocaleString(), end.toLocaleString());


// const weekOrders = data.filter((item) => {
//   return setChart === '' ? item :
//   item.date >=  start && item.date <= end;
  
// })

// console.log(weekOrders)


    const chartName = {
      
        series: [{
            name: "Desktops",
            data: [chartDate.length, yesterdayOrders.length, days2AgoOrders.length, days3AgoOrders.length, days4AgoOrders.length, days5AgoOrders.length, days6AgoOrders.length]
        }],
        options: {
          chart: {
            height: 250,
            type: 'line',
            zoom: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          title: {
            text: 'Sales Anaylatics',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: [currentDate, yesterdayDate, days2Ago, days3Ago, days4Ago, days5Ago, days6Ago],
          }
        },
      
      
      };




      const chartNameRevenue =  {
          
        series: [currentRevenueChart,yesterdayRevenueChart,day2AgoRevenueChart, day3AgoRevenueChart, day4AgoRevenueChart],
        options: {
          chart: {
            width: 380,
            type: 'pie',
          },
          labels: [currentDate, yesterdayDate, days2Ago, days3Ago, days4Ago],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      
      
      };


      const topSalesData = {
          
        series: [{
          data: [topProductsRank[0],topProductsRank[1],topProductsRank[2],topProductsRank[3],topProductsRank[4],]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: 'end',
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [topProductsName[0],topProductsName[1],topProductsName[2],topProductsName[3],topProductsName[4],
            ],
          }
        },
      
      
      };



      const topCitiesChart = {
          
        series: [{
          data: [topCitiesRank[0],topCitiesRank[1],topCitiesRank[2],topCitiesRank[3],topCitiesRank[4],]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: 'end',
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [topCitiesNameData[0],topCitiesNameData[1],topCitiesNameData[2],topCitiesNameData[3],topCitiesNameData[4],
            ],
          }
        },
      
      
      };

     
        return (
          <div>
            <div className='box-shadow' style={{marginTop:20, backgroundColor:'white'}} id="chart">
              <ReactApexChart options={chartName.options} series={chartName.series} type="line" height={350} />
            </div>
            <div id="html-dist"></div>
            <ColumnChart />
            <hr/>
            <div>
          
        <div className='box-shadow' style={{marginTop:20, backgroundColor:'white'}} id="chart">
              <ReactApexChart options={chartNameRevenue.options} series={chartNameRevenue.series} type="pie" height={350} />
            </div>
            <div id="html-dist"></div>
            
            </div>

            <div>
          
            <div>
                    <Box  sx={{ flexGrow: 1,mt:5 }}>
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div>
              <div  id="chart">
                <h3>Top Products</h3>
                <ReactApexChart options={topSalesData.options} series={topSalesData.series} type="bar" height={350} />
              </div>
              <div id="html-dist"></div>
            </div>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div id="chart">
                            <h3>Top Cities</h3>
                <ReactApexChart options={topCitiesChart.options} series={topCitiesChart.series} type="bar" height={350} />
              </div>
              <div id="html-dist"></div>
                              
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            
            </div>
          </div>


        );
      
    }

  

  
  
