import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Edit } from "../../firebase/firebasemethod";
import Base_Url from "../../url";


function EditCoupon() {
    let location = useLocation();
    // console.log(location.state);
    let [coupon, setCoupon] = useState(location.state);

    const updateFN = () => {
        Edit(coupon, "Coupons", coupon.id).then(() => {
            alert("Data is successfully updated");
        }).catch(() => {
            alert("Something is wrong");
        })
     
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input fullWidth placeholder='Product Name' variant='standard' value={coupon.name} onChange={e => setCoupon({ ...coupon, name: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input fullWidth placeholder='Product Price' variant='standard' value={coupon.id} onChange={e => setCoupon({ ...coupon, id: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input fullWidth placeholder='Product Special Price' variant='standard' value={coupon.discount}  onChange={e => setCoupon({ ...coupon, discount: e.target.value })} />
                    </Grid>
                </Grid>
            </Box>
            <button style={{marginTop:10, padding:10, background:'yellow', color:'black', border:'none',fontSize:13}} onClick={updateFN}>Update data</button>
        </>
    )
};


export default EditCoupon;