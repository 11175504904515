import { Button, CircularProgress, Divider } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField } from '@mui/material';

const CheckAdmin = () => {

    const navigate = useNavigate();

    const [model, setModel] = useState('')
    const [isLoading, setLoading] = useState(false)

    const checkAdmin = () => {
        setLoading(true)
        if (model.username === "adminadmin" && model.password === "Modestile@123") {
            setLoading(false)
            alert('Success');
            navigate('/a5fgh4-0565-hgseu-4s5as-s54s54d5sh5ggs');
        } else {
            setLoading(false)
            alert('Error')
        }
    }

    return (
        <div className="container containerl">
        <div className="main">
          <Box sx={{ mb: 2 }}>
            <img src='images/logo.png' alt="TodoLogo" width="50%" />
          </Box>
          <h3>Admin Login</h3>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ pb: 2 }}>
                <input
                  placeholder="Email"
                  onChange={(e) => setModel({...model, username: e.target.value})}
                  type="text"
                  // variant="standard"
                  // sx={{ width: "90%" }}
                  style={{border:'none',borderBottom:'1px solid black'}}
                size="70"
                />
              </Box>
              <Box sx={{ pb: 2 }}>
                <input
                  placeholder="Password"
                  onChange={(e) => setModel({...model, password: e.target.value})}
                  type="password"
                  style={{border:'none',borderBottom:'1px solid black'}}
                  // variant="standard"
                  size="70"
                  // sx={{ width: "90%" }}
                />
              </Box>
            </Box>
            <Box sx={{ p: 2 }}>
              <Button style={{width:'90%'}} className="button" variant="contained" onClick={checkAdmin} fullWidth>
              {isLoading? <CircularProgress color="inherit" />:"Login" }
              </Button>
            </Box>
          </Box>
          
        </div>
      </div>
    )
}

export default CheckAdmin;