import {  CircularProgress, Grid, Paper, setRef, Switch, Table, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { ref, uploadBytes } from 'firebase/storage'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { storage } from '../../firebase/firebaseconfig'
import { deleteItem, Edit, getData, sendData } from '../../firebase/firebasemethod';
import { uploadImage } from '../../firebase/firebaseMethod1';
import { v4 } from 'uuid'
import { Database } from 'firebase/database'
import axios from 'axios'
import { useNavigate, useNavigation } from 'react-router-dom'
import './style.css'
import Base_Url from '../../url'
import { Button } from '../../styles/Button'
import DeleteIcon from '@mui/icons-material/Delete';
import { CoPresentOutlined } from '@mui/icons-material'

const CouponHome = () => {
    const [coupon, setCoupon] = useState([])
    const [getProduct, setGetProduct] = useState([])
    console.log(getProduct);
    const naviagate = useNavigate()
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)
  
    
    const [edit, setEdit] = useState(true)

    console.log(edit)

    const editValue = {
        
        status: edit?'activate' : 'inactivate'
    }

    console.log(editValue.status)
    
    
        useEffect(()=>{
            getData('Coupons').then((res)=>{
                console.log('Data get')
                setGetProduct(Object.values(res))
            }).catch((err)=>{
                console.log('Data not get ',err)
            })
          },[refresh])
        
  console.log(getProduct)

    const Delete=(e)=>{
       deleteItem('Coupons', e)
       setRefresh(!refresh)
       alert('Data Delete Successfully')
    }

   

  

    const updateFN = (e) => {
        Edit(editValue, "Coupons", e.id).then((res) => {
            console.log(res)
            alert("Data is successfully updated");
        }).catch(() => {
            alert("Something is wrong");
        })
     
    }
    
    const createCoupon = () => {
        setLoading(true)
        sendData(coupon, "Coupons",coupon.id).then(() => {
            alert("Successfully upload")
            setLoading(false)
            setRefresh(!refresh)
            // window.location.reload()
        }).catch(() => {
            setLoading(false)
            alert("sOMETHING IS WRONG");
        })
    }

    const handleClick = () =>{
        naviagate('Add-Coupon')
    }

    return (
        <>
        <div style={{display:'flex',justifyContent:'space-between'}}>

                    <h3 className='regisdetail'>All Coupon Details</h3>
        <Button style={{backgroundColor:'#edc531', padding:10, border:'none', cursor:'pointer'}} onClick={handleClick}>+ Coupon</Button>

        </div>
         
    
            <div>
                <Box sx={{ mt: 5 }}>



                    <Table responsive border={1} hover sx={{ mr: 20 }} component={Paper}>
                        <thead>
                            <tr>
                                <th style={{ paddingLeft: 30, paddingRight: 30, fontSize:17 }}>#</th>
                                <th style={{ paddingLeft: 30, paddingRight: 30, fontSize:17 }}>Name</th>
                                <th style={{ paddingLeft: 30, paddingRight: 30, fontSize:17 }}>Code</th>
                                <th style={{ paddingLeft: 30, paddingRight: 30, fontSize:17 }}>Discount</th>
                                <th style={{ paddingLeft: 30, paddingRight: 30, fontSize:17 }}>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getProduct.map((e, i) => {
                                    console.log(e.id)
                                    return (
                                        <tr className='rowStyle' key={i}>
                                            <td className='productData' style={{fontSize:15,padding:15}}>{i + 1}</td>
                                            <td className='productData' style={{fontSize:15}}>{e.name}</td>
                                            <td className='productData' style={{fontSize:15}}>{e.id}</td>
                                            <td className='productData' style={{fontSize:15}}>{e.discount}</td>
                                            {/* <td>
                                            <div className='productData' sx={{ color: 'black' }}><Switch onClick={e => updateFN(e)} onChange={(e) => setEdit(e.target.checked)} color='success'  defaultChecked /></div>
                                            </td> */}
                                            <td>
                                            <div className='productData' onClick={()=>Delete(e.id)} style={{color:'red',cursor:'pointer'}}><DeleteIcon /></div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>

                </Box>
            </div>
        </>

    )
}

export default CouponHome;
