import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { sendData } from "../../firebase/firebasemethod";
import './style.css'

const AddCoupon = () =>{
const [coupon, setCoupon] = useState()
const [loading, setLoading] = useState(false)
const [type, setType] = useState('')

    const createCoupon = () => {
        setLoading(true)
        if(type === ""){
            alert('Please Select Type')
            return;
        }else{
            sendData(coupon, "Coupons",coupon.id).then(() => {
                alert("Successfully upload")
                setLoading(false)
                // window.location.reload()
            }).catch(() => {
                setLoading(false)
                alert("SOMETHING IS WRONG");
            })
        }
       
    }

    console.log(type)

return(
    <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={4} lg={4}>
            <input fullWidth style={{border:'none', borderBottomWidth:1, borderBottomColor:'black', borderBottomStyle:'solid'}} placeholder='Coupon Name' variant='standard' onChange={e => setCoupon({ ...coupon, name: e.target.value, })} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
        <select  style={{padding:10, borderRadius:10}} onChange={(e)=>setType(e.target.value)}>
                       <option value={''}>Select Type</option>
                       <option value={'Percentage'}>Percentage</option>
                       <option value={'Rupees'}>Rupees</option>
                   </select>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
            <input fullWidth style={{border:'none', borderBottomWidth:1, borderBottomColor:'black', borderBottomStyle:'solid'}} placeholder='Coupon Code' variant='standard' onChange={e => setCoupon({ ...coupon, id: e.target.value })} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
            <input fullWidth style={{border:'none', borderBottomWidth:1, borderBottomColor:'black', borderBottomStyle:'solid'}} placeholder={type=='Percentage'?('Discount in Percentage'):('Discount in Rupees')} variant='standard' onChange={e => setCoupon({ ...coupon, discount: e.target.value, status:'activate', type: type })} />
        </Grid>
       
    </Grid>

    <button style={{backgroundColor:'#edc531', padding:10, border:'none', cursor:'pointer', marginTop:20}} onClick={createCoupon}>{loading? ('Loading....'):('Add Coupon')}</button>
</Box>
)
}

export default AddCoupon;