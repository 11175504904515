import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {  deleteItem, getData } from '../../firebase/firebasemethod';
import Paper from '@mui/material/Paper';
import './style.css'
import { useNavigate } from 'react-router-dom';
import { Button, ButtonBase } from '@mui/material';


export default function Orders() {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        getData('ordersNew')
            .then((res) => {
                setData(Object.values(res))
                // console.log(res);
                setIsLoading(false)
            })
            .catch((err) => {
                alert(err);
                setIsLoading(false)
            })
    }, []);
 

    //   console.log(data)
  
  
     

    const handleClick = (e)=>{
        navigate(`/a5fgh4-0565-hgseu-4s5as-s54s54d5sh5ggs/detail`,{
            state:e,
        })}

    
    // console.log(data)

    return (
    
        <div>
            <Box sx={{mt:1}}>
            
            <h3 className='regisdetail' style={{textAlign:'center',fontSize:25}}>All Order Details</h3>
            {
                isLoading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                        <CircularProgress />
                    </Box>
                    :

                    <Table responsive hover sx={{mr:20}} component={Paper}>
                        <thead>
                            <tr>
                                <th style={{paddingLeft:10,paddingRight:10,fontSize:18}}>Sr.</th>
                                <th style={{paddingLeft:40,paddingRight:40,fontSize:18}}>#ID</th>
                                <th style={{paddingLeft:40,paddingRight:40,fontSize:18}}>Date</th>
                                <th style={{paddingLeft:40,paddingRight:40,fontSize:18}}>Consignee</th>
                                <th style={{paddingLeft:40,paddingRight:40,fontSize:18}}>Contact</th>
                                <th style={{paddingLeft:40,paddingRight:40,fontSize:18}}>Remarks</th>
                                <th style={{paddingLeft:40,paddingRight:40,fontSize:18}}>Details</th>
                                <th style={{paddingLeft:40,paddingRight:40,fontSize:18}}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.reverse().map((e, i) => {
                                    return (
                                        <tr className='rowStyle' key={i}>
                                            <td style={{fontSize:15,textAlign:'center'}}>{i+1}</td>
                                            <td style={{fontSize:15,textAlign:'center'}}>{e.orderid?`#${e.orderid}`:null}</td>
                                            <td style={{fontSize:15,textAlign:'center'}}>{e.date}</td>
                                            <td style={{fontSize:15,textAlign:'center'}}>{e.firstname}</td>
                                            <td style={{fontSize:15,textAlign:'center'}}>{e.number1}</td>
                                            <td style={{fontSize:15,textAlign:'center'}}>{e.remarks? e.remarks: null}</td>
                                            <td style={{fontSize:15,textAlign:'center',padding:2}}>
                                            <Button onClick={()=>handleClick(e)} sx={{padding:1,fontSize:12,color:'#fdb833'}}>More Details</Button>
                                            </td>
                                            {e.status == 'pending'? (
                                        <td style={{ fontSize: 18, marginTop: 8, color: 'orange', paddingRight: 35,textAlign:'center' }}>Pending</td>
                                    ) : e.status == 'manufacture'? (
                                        <td style={{ fontSize: 18, marginTop: 8., color: 'golden', paddingRight: 35,textAlign:'center' }}>Manufacture</td>
                                    ) : e.status == 'cancel'? (
                                        <td style={{ fontSize: 18, marginTop: 8., color: 'red', paddingRight: 35,textAlign:'center' }}>Cancel</td>
                                    ) : e.status == 'shipped'? (
                                        <td style={{ fontSize: 18, marginTop: 8., color: 'skyblue', paddingRight: 35,textAlign:'center' }}>Shipped</td>
                                    ) : e.status == 'rts'? (
                                        <td style={{ fontSize: 18, marginTop: 8., color: 'blue', paddingRight: 35,textAlign:'center' }}>Ready to Ship</td>
                                    ) : e.status == 'return'? (
                                        <td style={{ fontSize: 18, marginTop: 8., color: 'orange', paddingRight: 35,textAlign:'center' }}>Return</td> ) :
                                        (
                                            <td style={{ fontSize: 18, marginTop: 8., color: 'green', paddingRight: 35,textAlign:'center' }}>Delivered</td> )
                                        
                                        }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
            }
            </Box>
            
        </div>
    );
}